import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import classNames from "classnames"
import Markdown from "highline/components/markdown"
import ProductTileImages from "highline/components/product_tile_images"
import ProductTileImage from "highline/components/category/product_tile_image"
import Link from "highline/components/secure_link"
import ProductTileSwatches from "highline/components/category/product_tile_swatches"
import SavedItemCta from "highline/components/saved_item_cta"
import getConfig from "highline/config/application"
import Watermark from "highline/components/watermark"
import FielderAccentBlk from "highline/svg/icons/fielder-accent-blk.svg"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"

import styles from "highline/styles/components/category/product_tile.module.css"

const { disableCategorySavedItemCta } = getConfig()

class ProductTile extends React.Component {
  state = {
    showAlternateProductImage: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selectedSwatchIndex != nextProps.selectedSwatchIndex ||
      this.props.link.href != nextProps.link.href ||
      this.props.primaryImageUrl != nextProps.primaryImageUrl ||
      this.props.isMobile != nextProps.isMobile ||
      this.props.isTablet != nextProps.isTablet ||
      this.props.saved != nextProps.saved ||
      this.props.promoPrice != nextProps.promoPrice ||
      this.state.showAlternateProductImage != nextState.showAlternateProductImage ||
      this.props.promotionExclusions != nextProps.promotionExclusions
    )
  }

  handleCTAClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { swatches, onCTAClick, selectedOptions, slug, selectedSwatchIndex } = this.props
    const options =
      swatches.size > 0
        ? selectedOptions.merge({
            color: swatches.getIn([selectedSwatchIndex, "colorName"]),
          })
        : selectedOptions
    onCTAClick(slug, options)
  }

  handleSavedItemCtaClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { slug, selectedOptions, saved, onSavedItemCtaClick } = this.props

    onSavedItemCtaClick(slug, selectedOptions, saved)
  }

  handleProductTileClick = () => {
    // extracting the click to a local function prevents hard navigation
    this.props.onProductTileClick()
  }

  render() {
    const {
      ariaLabel,
      className,
      constructorTrackingId,
      countDetails,
      ctaText,
      fullPrice,
      isFinalSale,
      isGiftCard,
      saved,
      itemKey,
      inStock,
      isBundle,
      isFielderProduct,
      isMobile,
      isTablet,
      isTruncateSwatches,
      layout,
      lazyLoad,
      link,
      linkAvailable,
      name,
      hideName,
      onProductImageHover,
      onSwatchClick,
      onSwatchMouseEnter,
      onSwatchMouseLeave,
      price,
      promoPrice,
      primaryImageUrl,
      priority,
      placeholder,
      recommendationPodId,
      recommendationStrategyId,
      isInline,
      isNewColor,
      isNewProgram,
      secondaryImageUrl,
      showCTA,
      showSavedItemCta,
      showSwatches,
      slug,
      subtitle,
      swatches,
      selectedSwatchIndex,
      title,
      noveltyBadge,
      urgencyBadge,
      promotionExclusions,
    } = this.props
    const { showAlternateProductImage } = this.state

    const swatchExcludedFromPromo = shouldExcludeProgram(
      slug,
      swatches.getIn([selectedSwatchIndex, "colorName"]) || title?.toLowerCase(),
      promotionExclusions?.toJS()
    )

    const isOnSale = fullPrice !== price
    const isFinalSaleCheck = isFinalSale || (isFinalSale === undefined && isOnSale)
    const isMarkdownOrPromo = isOnSale || (!swatchExcludedFromPromo && promoPrice)
    const priceToDisplay = swatchExcludedFromPromo ? price : promoPrice || price

    const removeDecimalsFromLink = (str) => str.split(".").join("")

    const linkOrNoLinkBody = (
      <>
        <div
          onMouseOver={
            isTablet
              ? null
              : () => {
                  this.setState({ showAlternateProductImage: true })
                  onProductImageHover()
                }
          }
          onMouseOut={() => {
            this.setState({ showAlternateProductImage: false })
          }}
          onBlur={() => {
            this.setState({ showAlternateProductImage: false })
          }}
          className={styles.productTileCard}
        >
          <ProductTileImages
            ariaLabel={ariaLabel}
            countDetails={countDetails}
            isFinalSale={isFinalSaleCheck}
            ctaText={ctaText}
            savedItemCtaComponent={
              showSavedItemCta && !disableCategorySavedItemCta ? (
                <SavedItemCta isSaved={saved} onClick={this.handleSavedItemCtaClick} />
              ) : null
            }
            imageComponent={
              <ProductTileImage
                name={name}
                primaryImageUrl={primaryImageUrl || secondaryImageUrl}
                secondaryImageUrl={secondaryImageUrl}
                showAlternateImage={showAlternateProductImage}
                priority={priority || !lazyLoad}
                placeholder={placeholder}
                isMobile={this.props.isMobile}
              />
            }
            inStock={inStock}
            onCTAClick={this.handleCTAClicked}
            showCTA={showCTA && !isBundle}
            showAlternateProductImage={showAlternateProductImage}
            lazyLoad={lazyLoad}
            layout={layout}
          />
          {noveltyBadge && <div className={classNames(styles.badgeLimited)}>{noveltyBadge}</div>}
          {(isNewProgram || isNewColor) && !noveltyBadge && (
            <div className={classNames(styles.badgeLimited)}>
              {isNewProgram ? "New" : "New Color"}
            </div>
          )}
          {isFielderProduct && <Watermark svg={<FielderAccentBlk />} placement="bottom-left" />}
        </div>

        <div className={classNames(styles.productDescription)}>
          <div className={styles.productContentContainer}>
            <div className={styles.productInfo}>
              {!hideName && <div className={styles.productName}>{name}</div>}

              {!isGiftCard && (
                <div className={styles.productPriceMobile}>
                  <span className={isMarkdownOrPromo ? styles.salePrice : styles.regularPrice}>
                    {priceToDisplay}
                  </span>

                  {isMarkdownOrPromo && <span className={styles.fullPrice}>{fullPrice}</span>}
                </div>
              )}
            </div>

            {!isGiftCard && (
              <div className={styles.productPriceDesktop}>
                <span className={isMarkdownOrPromo ? styles.salePrice : styles.regularPrice}>
                  {priceToDisplay}
                </span>

                {isMarkdownOrPromo && <span className={styles.fullPrice}>{fullPrice}</span>}
              </div>
            )}
          </div>
          {title && (
            <div className={classNames("title", styles.title)}>
              <p>{title}</p>
            </div>
          )}
        </div>
      </>
    )

    const linkOrNoLink =
      linkAvailable && inStock ? (
        <Link
          href={removeDecimalsFromLink(link.get("href"))}
          as={removeDecimalsFromLink(link.get("as"))}
          onClick={this.handleProductTileClick}
          onFocus={() => {
            //Triggers the Quick Shop button to be visible when the product is tabbed over *ADA
            this.setState({ showAlternateProductImage: true })
            onProductImageHover()
          }}
          onKeyDown={(k) => {
            //Remove Quick Shop from previously focused items when tabbing backwards between products *ADA
            if (k.shiftKey === true) {
              this.setState({ showAlternateProductImage: false })
            }
          }}
        >
          {linkOrNoLinkBody}
        </Link>
      ) : (
        <div>{linkOrNoLinkBody}</div>
      )

    return (
      <div
        className={classNames(
          className,
          "component",
          "product-tile-component",
          styles.component,
          isInline && styles.inline,
          styles[layout]
        )}
        data-product-id={constructorTrackingId}
        data-pod-id={recommendationPodId}
        data-strategy-id={recommendationStrategyId}
        data-cnstrc-item-id={constructorTrackingId}
        data-cnstrc-item-name={name}
        data-cnstrc-strategy-id={recommendationStrategyId}
        data-cnstrc-item-price={priceToDisplay}
        data-cnstrc-item="recommendation"
        data-cnstrc-item-variation-id={subtitle}
      >
        {linkOrNoLink}
        {subtitle && (
          <div className={classNames("subtitle", styles.subtitle)}>
            <Markdown source={subtitle} />
          </div>
        )}
        {showSwatches && (
          <div className={classNames(styles.swatches, isTruncateSwatches && styles.leftAligned)}>
            <ProductTileSwatches
              isMobile={isMobile}
              isTruncateSwatches={isTruncateSwatches}
              link={link}
              onSwatchClick={(selectedIndex) => onSwatchClick(itemKey, selectedIndex)}
              onSwatchMouseEnter={(activatedIndex) => onSwatchMouseEnter(itemKey, activatedIndex)}
              onSwatchMouseLeave={() => onSwatchMouseLeave(itemKey)}
              selectedSwatchIndex={selectedSwatchIndex}
              swatches={swatches}
            />
          </div>
        )}
        {urgencyBadge && (
          <div className={classNames(styles.urgencyBadge, "urgency-badge")}>{urgencyBadge}</div>
        )}
        {showCTA && inStock && !isBundle && (
          <button
            aria-label={ariaLabel}
            className={styles.ctaButtonMobile}
            onClick={this.handleCTAClicked}
            data-cnstrc-btn={ctaText}
          >
            + {ctaText}
          </button>
        )}
      </div>
    )
  }
}

ProductTile.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  constructorTrackingId: PropTypes.string,
  countDetails: PropTypes.string,
  ctaText: PropTypes.string,
  title: PropTypes.string,
  fullPrice: PropTypes.string,
  saved: PropTypes.bool,
  itemKey: PropTypes.string,
  inStock: PropTypes.bool,
  isBundle: PropTypes.bool,
  isFielderProduct: PropTypes.bool,
  isFinalSale: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  isTruncateSwatches: PropTypes.bool,
  layout: PropTypes.oneOf(["defaultLayout", "accompanyingProductsLayout"]),
  lazyLoad: PropTypes.bool,
  link: ImmutablePropTypes.map,
  linkAvailable: PropTypes.bool,
  name: PropTypes.string,
  hideName: PropTypes.bool,
  onCTAClick: PropTypes.func,
  onSavedItemCtaClick: PropTypes.func,
  onProductImageHover: PropTypes.func,
  onProductTileClick: PropTypes.func,
  onSwatchClick: PropTypes.func,
  onSwatchMouseEnter: PropTypes.func,
  onSwatchMouseLeave: PropTypes.func,
  price: PropTypes.string,
  promoPrice: PropTypes.string,
  promotionExclusions: ImmutablePropTypes.map,
  primaryImageUrl: PropTypes.string,
  priority: PropTypes.bool,
  placeholder: PropTypes.string,
  isInline: PropTypes.bool,
  isNewColor: PropTypes.bool,
  isNewProgram: PropTypes.bool,
  recommendationPodId: PropTypes.string,
  recommendationStrategyId: PropTypes.string,
  secondaryImageUrl: PropTypes.string,
  selectedOptions: ImmutablePropTypes.map,
  showCTA: PropTypes.bool,
  showSavedItemCta: PropTypes.bool,
  showSwatches: PropTypes.bool,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
  swatches: ImmutablePropTypes.list,
  selectedSwatchIndex: PropTypes.number,
  noveltyBadge: PropTypes.string,
  urgencyBadge: PropTypes.string,
}

ProductTile.defaultProps = {
  constructorTrackingId: "",
  saved: false,
  inStock: true,
  isBundle: false,
  isMobile: false,
  isTablet: false,
  isTruncateSwatches: false,
  isFielderProduct: false,
  isFinalSale: false,
  isGiftCard: false,
  lazyLoad: true,
  layout: "defaultLayout",
  link: fromJS({}),
  linkAvailable: true,
  onProductImageHover: () => {},
  onProductTileClick: () => {},
  onSavedItemCtaClick: () => {},
  onSwatchClick: () => {},
  onSwatchMouseEnter: () => {},
  onSwatchMouseLeave: () => {},
  promotionExclusions: fromJS({}),
  isInline: false,
  isNewColor: false,
  isNewProgram: false,
  selectedOptions: fromJS({}),
  showCTA: false,
  showSavedItemCta: false,
  showSwatches: false,
  slug: "",
  swatches: fromJS([]),
  selectedSwatchIndex: 0,
  itemKey: "defaultKey",
  noveltyBadge: "",
  urgencyBadge: "",
  hideName: false,
}

export default ProductTile
