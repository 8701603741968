import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { fromJS } from "immutable"
import HorizontalScrollArea from "highline/components/horizontal_scroll_area"
import { productRecommendations } from "highline/api/constructor_api"
import ProductTile from "highline/components/category/product_tile"
import {
  productRecommendationClicked,
  productRecommendationsDisplayed,
} from "highline/redux/actions/recommendation_actions"
import { productPreviewClicked } from "highline/redux/actions/category_actions"
import {
  getDiscountedPrice,
  isProductAFielderProduct,
} from "highline/redux/helpers/product_detail_helper"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"
import styles from "highline/styles/components/recommendations/product_recommendations.module.css"
import {
  useSelectPromoDiscount,
  useSelectPromoExclusionsStatus,
  useSelectPromoExclusionsList,
} from "highline/selectors/contentful/contentful_selectors"
import { generateLink } from "redux/helpers/filters_helper"
import { LOAD_STATUS } from "highline/utils/constants.js"

export const ProductRecommendations = ({
  productId,
  podId,
  numResults,
  productsLoaded,
  filterOnSale,
  categoryFilter,
}) => {
  const dispatch = useDispatch()
  const promoDiscount = useSelector(useSelectPromoDiscount)
  const promoExclusionsStatusPending =
    useSelector(useSelectPromoExclusionsStatus) === LOAD_STATUS.PENDING
  const [products, setProducts] = useState(fromJS([]))
  const promotionExclusions = useSelector(useSelectPromoExclusionsList)

  function handleQuickShopClick(product) {
    const slug = product.get("slug")
    const options = fromJS({ color: product.get("currentColor") })
    dispatch(productPreviewClicked(slug, options))
  }

  function handleProductTileClick(product) {
    dispatch(
      productRecommendationClicked(
        product.get("slug"),
        product.get("id"),
        product.get("currentColor")
      )
    )
  }

  const fetchRecommendations = useCallback(async () => {
    try {
      const recommendationsResponse = await productRecommendations(productId, podId, numResults, {
        filterOnSale,
        categoryFilter,
      })

      if (recommendationsResponse) {
        // If Fielder Product - do not show as a product recommendation
        const filteredProducts = recommendationsResponse.filter((product) => {
          const slug = product.get("slug")

          return slug ? !isProductAFielderProduct(product.get("slug")) : false
        })

        setProducts(filteredProducts)

        productsLoaded(filteredProducts.size)
      }
    } catch {
      setProducts(fromJS([]))
    }
  }, [categoryFilter, filterOnSale, numResults, podId, productId, productsLoaded])

  const onMount = useCallback(() => {
    if (products.size > 0) {
      dispatch(productRecommendationsDisplayed())
    }
  }, [products, dispatch])

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    fetchRecommendations()
  }, [fetchRecommendations])

  return (
    <div
      data-cnstrc-recommendations
      data-cnstrc-recommendations-pod-id={podId}
      data-cnstrc-num-results={numResults}
    >
      <HorizontalScrollArea layout="productTiles" className={styles.productRecommendationsSpacing}>
        {products.map((product, index) => {
          const link = generateLink(
            product.get("slug"),
            product.get("currentColor"),
            fromJS([]),
            product.get("isGiftCard"),
            product.get("isBundle")
          )
          const numericFullPrice = parseInt(product.get("price").substring(1))
          const quickShop = {
            ctaText: "Quick Shop",
            onCTAClick: () => handleQuickShopClick(product),
            showCTA: true,
          }
          const shouldApplySitewidePromo =
            promoDiscount &&
            !shouldExcludeProgram(
              product.get("slug"),
              product.get("currentColor"),
              promotionExclusions?.toJS()
            )
          const shouldDisplaySitewidePromo =
            shouldApplySitewidePromo && !promoExclusionsStatusPending
          const promoPrice = shouldDisplaySitewidePromo
            ? `$${getDiscountedPrice(numericFullPrice, promoDiscount)}`
            : null

          const recommendationPodId = Boolean(product.get("podId"))
            ? product.get("podId")
            : productId

          return (
            <ProductTile
              {...product.toJS()}
              {...quickShop}
              className={`${styles.productTile} ${styles.paddingNone}`}
              ariaLabel={`Open Quick Shop for ${product.get("name")}`}
              subtitle={product.get("currentColor")}
              key={`product-rec-${index}`}
              recommendationPodId={recommendationPodId}
              recommendationStrategyId={product.get("strategyId")}
              constructorTrackingId={product.get("id")}
              onProductTileClick={() => handleProductTileClick(product)}
              link={link}
              promoPrice={promoPrice}
              promotionExclusions={promotionExclusions}
              showSwatches={false}
            />
          )
        })}
      </HorizontalScrollArea>
    </div>
  )
}

ProductRecommendations.propTypes = {
  numResults: PropTypes.number,
  productId: PropTypes.string,
  productsLoaded: () => {},
  podId: PropTypes.string,
  filterOnSale: PropTypes.string,
  categoryFilter: PropTypes.string,
}

ProductRecommendations.defaultProps = {
  numResults: 0,
  productId: "",
  podId: "",
  productsLoaded: () => {},
}

export default ProductRecommendations
